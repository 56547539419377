// @flow
import React from 'react';
import type { FrontMatter } from '../utils/types';
import {
  GlossaryLink as G,
  Layout,
  Link,
  withFrontMatter,
} from '../components';
import route from '../routes';

type Props = {
  frontMatter: FrontMatter,
};

class Canyoneering_Dear extends React.Component<Props> {
  render() {
    const { frontMatter } = this.props;

    return (
      <Layout frontMatter={frontMatter} toc>
        <h2>What is DEAR?</h2>
        <p>
          Acronym for remembering the ideal anchor location. Let's go over what
          each letter means.
        </p>
        <h2>Dry</h2>
        <p>
          This is <G>canyoneering</G>. Canyoneers descend <G>canyons</G>.
          Canyons are formed by water and in particular <G>flash floods</G>.
          Flash floods destroy everything, including <G>anchors</G>. We want our
          anchors away from water as much as possible.
        </p>
        <p>
          For <G>bolts</G> we want them to be out of the main <G>drainage</G>{' '}
          where we walk. They should be high up enough that they don't get hit
          often with flood waters. We also want them out of any drainages coming
          from above. You can usually tell where streams come down by their
          discoloration compared with the rest of the wall.
        </p>
        <h2>Efficient</h2>
        <p>
          We want it to be as close to the drop as possible. If we have a choice
          between a large <G>natural anchor</G> right next to the <G>rappel</G>{' '}
          and a large natural anchor 50 feet away from the rappel we should
          choose the large natural anchor right next to the rappel.
        </p>
        <p>
          Other things that would be efficient would be to avoid places with
          lots of loose rocks that would be a hazard for canyoneers down below.
          We should also avoid <G>cryptobiotic soil</G>, bee hives, or other
          potential hazards.
        </p>
        <h2>Accessible</h2>
        <p>
          It should be easy and safe to access the anchor. Canyoneers shouldn't
          have to climb up anything or <G>traverse</G> an{' '}
          <G id="exposure">exposed</G> stretch to access it.
        </p>
        <h2>Rope Retrieval</h2>
        <p>
          This one is often counter to many of the other priorities already
          outlined above. The anchor location should minimize friction when
          pulling down the rope. This often means compromising between this and
          accessibility or efficiency.
        </p>
        <p>
          To solve the problem of inefficient anchors, tough rappel starts, and
          difficult rope retrievals we use <G>courtesy rigging</G>.
        </p>
        <p>
          See my article on <Link to={route.page_pull}>The Pull</Link> for more
          information on rope retrieval.
        </p>
        <h2>Case studies</h2>
        <h3>Behunin Canyon</h3>
        <p>
          The final rappel in{' '}
          <Link to="http://ropewiki.com/Behunin_Canyon">Behunin Canyon</Link>{' '}
          has two or three bolts with chains attached to them. There are chain
          grooves in the rock that I presume are from flash floods tossing the
          chains around. Mostly Dry but not always, Efficient, Accessible, good
          Rope Retrieval.
        </p>
        <h3>Mystery Canyon</h3>
        <p>
          The Mystery Springs rappel in{' '}
          <Link to="http://ropewiki.com/Mystery_Canyon">Mystery Canyon</Link>{' '}
          has an exposed traverse to get to the rappel anchors. However the rope
          retrieval is much simpler and avoids a huge <G>chockstone</G>. Dry,
          Efficient, not very Accessible, but good Rope Retrieval.
        </p>
        <h3>Zero Gravity Canyon</h3>
        <p>
          The final rappel in{' '}
          <Link to="http://ropewiki.com/Zero_G_Canyon">
            Zero Gravity Canyon
          </Link>{' '}
          used to be a chockstone above a <G>Mae West slot</G>. Canyoneers would
          rig and then have to traverse horizontally to where the canyon bottom
          opened enough to fit through. The canyoneer would basically down climb
          until they fell and then swing back. Now there is at least one bolt
          that <G>Search and Rescue</G> uses so this R-rated rappel isn't
          necessary anymore. It used to be Dry, not very Efficient, not very
          Accessible, and good Rope Retrieval. Now it is DEAR but not nearly as
          fun.
        </p>
        <h3>Lomatium Canyon</h3>
        <p>
          The big rappel in{' '}
          <Link to="http://ropewiki.com/Lomatium_Canyon">Lomatium Canyon</Link>{' '}
          has has three different anchors since I first descended it. We used to
          rappel from Abbey Arch but Arches National Park put a policy in place
          that said rappelling from arches was no longer allowed. Consequently
          the new anchor was a skinny tree about 50 feet back from the rappel.
          This was inefficient, had a difficult pull, and was killing the tree.
          There was some discussion about it and some considerate canyoneers and
          park rangers went and installed some bolts closer to the edge. It is
          now in a DEAR location.
        </p>
        <h3>Spry Canyon</h3>
        <p>
          There are two noteworthy rappels in{' '}
          <Link to="http://ropewiki.com/Spry_Canyon">Spry Canyon</Link>.
          <br />
          The first one is about half way through the canyon. In order to reach
          the pair of bolts and webbing one has to lean over a cliff to the
          opposing wall where the bolts are located. It's something I would do
          without a second thought if there weren't a huge hole below me but in
          this circumstance I always go slowly. Dry, Efficient, not very
          Accessible, great Rope Retrieval.
          <br />
          The second one is closer to the end of the narrows. It uses a large
          tree as its anchor. The webbing used to be tied in a{' '}
          <G>redundant wrap</G> at the base of the tree. This, however, has led
          to some of the worst rope grooves I have ever seen. Great location
          except for the horrible rope retrieval. More recently the webbing was
          changed to a <G>wrap 3 pull 2</G> higher up in the tree. This simple
          change fixed the rope groove problem as well as improved the pull.
          Perfectly DEAR now.
        </p>
      </Layout>
    );
  }
}

export default withFrontMatter('dear')(Canyoneering_Dear);
